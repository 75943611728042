<template>
  <div class="container">
    <div class="title">
      <img src="./img/top.png" alt="" />
    </div>
    <div class="nav">
      <div
        class="day"
        @click="showRight = 1"
        :class="showRight == 1 ? 'isActive' : ''"
      >
        <!-- <img src="./img/day.png" alt="" /> -->
        <span>每日签到</span>
      </div>
      <div
        class="quest"
        @click="showRight = 2"
        :class="showRight == 2 ? 'isActive' : ''"
      >
        <!-- <img src="./img/quest.png" alt="" /> -->
        <span>任务中心</span>
      </div>
      <div
        class="quest"
        @click="showRight = 3"
        :class="showRight == 3 ? 'isActive' : ''"
      >
        <!-- <img src="./img/chongzhi.png" alt="" /> -->
        <span>充值补给</span>
      </div>
    </div>
    <div class="right">
       <div class="day" v-if="showRight == 1">
              <!-- 登陆签到 -->
              <div class="loginQ">
                <div class="title">
                  <div>
                    <span style="color: #ffc600; font-weight: 600"
                      >登陆签到</span
                    >
                    <span style="margin: 0 7px">|</span>
                    <span
                      >已签到<span style="margin: 0 7px; color: #ffc600">{{qusign}}</span
                      >天</span
                    >
                  </div>
                  <div class="rightT">
                    <span v-if="sign==0" @click="goSign(1)">签到</span>
                    <span v-else>已签到</span>
                  </div>
                </div>
                <!-- 签到按钮 -->
                <div class="lists">
                  <div class="item" v-for="(item,index) in 7" :key="index" :class="[item<=qusign?'itemActive':'',index==qusign&&sign==0?'keActive':'']">
                    <img src="./img/dui.png" alt="" v-if="item<=qusign" style="width:30px;height:auto">
                    <span class="cont1" v-else><span style="font-size:24px;margin-bottom:-7px;font-weight: 700;">{{item}}</span><span>Day</span></span>

                  </div>
                </div>
                <!-- 下方描述文字 -->
                <div class="description" style="color:#717382;font-size:14px">
                  连续7天登陆签到，第7天最高可获取红包福利（中断任何一天将充值回第一天）
                </div>
              </div>
              <!-- 充值签到 -->
              <div class="chargeQ" style="margin-top:50px">
                <div class="title">
                  <div>
                    <span style="color: #ffc600; font-weight: 600"
                      >充值签到</span
                    >
                    <span style="margin: 0 7px">|</span>
                    <span
                      >已签到<span style="margin: 0 7px; color: #ffc600">{{qusign_recharge}}</span
                      >天</span
                    >
                  </div>
                  <div class="rightT">
                    <span v-if="sign_recharge==0" @click="goSign(2)">签到</span>
                    <span v-else>已签到</span>
                  </div>
                </div>
                <!-- 签到按钮 -->
                <div class="lists">
                  <div class="item" v-for="(item,index) in 7" :key="index" :class="[(index + 1)<=qusign_recharge?'itemActive':'',index==qusign_recharge?'keActive':'']">
                    <img src="./img/dui.png" alt="" v-if="item<=qusign_recharge" style="width:30px;height:auto">
                    <span class="cont1" v-else><span style="font-size:24px;margin-bottom:-7px;font-weight: 700;">{{item}}</span><span>Day</span></span>
                    
                  </div>
                </div>
                <!-- 下方描述文字 -->
                <div class="description" style="color:#717382;font-size:14px">
                  连续7天登陆签到，第7天最高可获取红包福利（中断任何一天将充值回第一天）
                </div>
              </div>
              <div class="win-box" v-if="winMoney">
                <div class="win">
                  <div class="win-conm">
                    <div class="win-tips">恭喜获得</div>
                  </div>
                  <div class="win-conm" style="color: white;display: flex;align-items: center;flex-flow: row;">
                    <div>{{winMoney}}</div><img src="./img/money.png" style="width: 24px;">
                  </div>
                  <div class="win-span2" @click="winMoney = null">放入背包</div>
                  <!-- winState = false;   上面一行的代码 -->
                </div>
              </div>
            </div>
      <div class="quest" v-if="showRight == 2">
        <div class="topNav">
          <div
            class="box"
            @click="showBox = 1"
            :class="showBox == 1 ? 'boxActive' : ''"
          >
            <img src="./img/box.png" alt="" />
            <span>宝箱任务</span>
          </div>
          <div
            class="box"
            @click="showBox = 2"
            :class="showBox == 2 ? 'boxActive' : ''"
          >
            <img src="./img/fight.png" alt="" />
            <span>对战任务</span>
          </div>
          <div
            class="box"
            @click="showBox = 3"
            :class="showBox == 3 ? 'boxActive' : ''"
          >
            <img src="./img/meng.png" alt="" />
            <span>亏损补偿</span>
          </div>
        </div>
        <div class="main">
          <!-- 宝箱任务 -->
          <div class="box1" v-if="showBox == 1">
            <div class="boxs" v-for="(item, index) in boxList" :key="index">
              <div class="xian"></div>
              <div class="bleft">
                <div class="c1">
                  每日消耗{{ item.lim }}钻石（<span>{{ item.ls }}</span
                  >/<span>{{ item.lim }}</span
                  >）
                </div>
                <div class="c2">
                  <div class="img">
                    <span>任务奖励：保底{{ item.min }} ，</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                  <div class="img">
                    <span>最高{{ item.max }}</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="bright" @click="consumeRecharge1(item, index)">
                <span v-if="[1].includes(item.status)" style="">已经领取</span>
                <span v-else>{{ item.status == 0 ? "GO" : "立即领取" }}</span>
              </div>
            </div>
          </div>
          <!-- 对战任务 -->
          <div class="box2" v-if="showBox == 2">
            <div class="boxs" v-for="(item, index) in fightList" :key="index">
              <div class="xian"></div>
              <div class="bleft">
                <div class="c1">
                  每日消耗{{ item.lim }}钻石（<span>{{ item.ls }}</span
                  >/<span>{{ item.lim }}</span
                  >）
                </div>
                <div class="c2">
                  <div class="img">
                    <span>任务奖励：保底{{ item.min }} ，</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                  <div class="img">
                    <span>最高{{ item.max }}</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="bright">
                <span v-if="[1].includes(item.status)">已经领取</span>
                <span @click="consumeRecharge2(item, index)" v-else>{{
                  item.status == 0 ? "GO" : "立即领取"
                }}</span>
              </div>
            </div>
          </div>
          <!-- 亏损补偿 -->
          <div class="box3" v-if="showBox == 3">
            <div class="boxs" v-for="(item, index) in lossList" :key="index">
              <div class="xian"></div>
              <div class="bleft">
                <div class="c1">亏损到达{{ item.upgrade }}钻石</div>
                <div class="c2">
                  <div class="img">
                    <span>当前亏损{{ item.loss }} ，</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                  <div class="img">
                    <span>补偿比例{{ item.reward }}%</span>
                    <img src="./img/money.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="bright">
                <span v-if="item.status == 2">已经领取</span>
                <span
                  v-else-if="item.status == 1"
                  @click="consumeRecharge3(item, index)"
                  >立即领取</span
                >
                <span v-else>不可领取</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chongzhi" v-if="showRight == 3">充值补给</div>
    </div>
    <!-- 领取成功弹窗 -->
    <div class="win-box" v-if="boxmoney">
      <div class="win">
        <div
          class="win-conm"
          style="
            color: white;
            display: flex;
            align-items: center;
            flex-flow: row;
            justify-content: center;
          "
        >
          <div class="win-conm">
            <div class="win-tips">恭喜获得</div>
          </div>
          <div>{{ boxmoney }}</div>
          <img src="@/assets/img/money.png" style="width: 24px" />
        </div>
        <div class="win-span2" @click="boxmoney = null">放入背包</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxmoney: "",
      showRight: 1,
      showBox: 1,
      boxList: [],
      fightList: [],
      lossList: [],
      // 完成7天签到奖励
      winMoney:0,
      // 登录是否签到 1是 0否
      sign:0,
      // 充值是否签到 1是 0否
      sign_recharge: 0,
      // 登录签到天数
      qusign: 0,
      // 充值签到天数
      qusign_recharge:0,
    };
  },
  mounted() {
    this.getBoxInfo();
    this.getFightInfo();
    this.getLossInfo();
    this.getSignInfo()
  },
  methods: {
    // 点击签到按钮进行签到
    goSign(type) {
      this.$axios({
        url: '/index/user/sign_in',
        method:'post',
        data:{type:type}
      }).then((res) => {
        if(res.data.status == 1) {
          // 签到后重新获取数据
          this.boxmoney = res.data.data.money
          this.$message({
            message:res.data.msg,
            type:'success',
            customClass: "messageStyle",
          })
          this.getSignInfo()
        }else{
          this.$message({
            message:res.data.msg,
            type:'warning',
            customClass: "messageIndex",
          })
        }
      })
    },
    // 获取签到数据
    getSignInfo() {
      this.$axios({
        url:'/index/user/sign',
        data:{}
      }).then((res) => {
        if(res.data.status == 1) {
          // console.log(res.data.data,'data')
          let data = res.data.data
          this.sign = data.sign
          this.sign_recharge = data.sign_recharge
          this.qusign = data.qusign
          this.qusign_recharge = data.qusign_recharge
        }
      })
    },
    // 获取宝箱任务数据
    getBoxInfo() {
      const params = {};
      this.$axios({
        url: "/index/Activity/openbox_reward_info",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.status == 1) {
          let data = res.data.data.info;
          data.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // console.log(data, "ooooooooooo");
          this.boxList = data;
        }
      });
    },
    async consumeRecharge1(item, index) {
      if (item.ls > item.lim) {
        const params = {
          id: item.id,
        };
        const { data } = await this.$axios.post(
          "/index/Activity/kaixiangtask",
          this.$qs.stringify(params)
        );
        if (data.status) {
          this.boxmoney = data.data;
          this.boxList[index].status = 1;
          this.boxList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message({
            message: "领取成功",
            type: "success",
            customClass: "messageStyle",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            customClass: "messageStyle",
          });
          // this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/",
        });
        this.showFuliBox = false;
      }
    },
    // 获取对战任务数据
    getFightInfo() {
      const params = {
        player_id: JSON.parse(localStorage.getItem("userInfo")).id,
      };
      this.$axios({
        url: "/index/Activity/battle_reward_info",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.data.status == 1) {
          let data = res.data.data.info;
          data.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // console.log(data, "ooooooooooo");
          this.fightList = data;
        }
      });
    },
    async consumeRecharge2(item) {
      if (item.ls > item.lim) {
        const params = {
          id: item.id,
          player_id: JSON.parse(localStorage.getItem("userInfo")).id,
        };
        const { data } = await this.$axios.post(
          "/index/Activity/battle_task",
          this.$qs.stringify(params)
        );
        if (data.status) {
          this.boxmoney = data.data;
          this.fightList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.fightList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message.success("领取成功");
        } else {
          this.fightList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/Lucky",
        });
        this.showFuliBox = false;
      }
    },
    // 获取亏损补偿数据
    getLossInfo() {
      const params = {
        player_id: JSON.parse(localStorage.getItem("userInfo")).id,
      };
      this.$axios({
        url: "/index/Loss/losslist",
        method: "post",
        data: {},
      }).then((res) => {
        console.log(res, "res");
        if (res.status == 200) {
          let data = res.data.data;
          // data.sort((a, b) => {
          //   return a["lim"] - b["lim"];
          // });
          // console.log(data, "ooooooooooo");
          this.lossList = data;
        }
      });
    },
    async consumeRecharge3(item, index) {
      // if (item.ls > item.lim) {
      const params = {
        loss_reward_id: item.id,
      };
      const { data } = await this.$axios.post(
        "/index/Loss/lossreceive",
        this.$qs.stringify(params)
      );
      if (data.status) {
        this.boxmoney = data.data;
        this.lossList[index].status = 1;
        this.lossList.sort((a, b) => {
          return a["lim"] - b["lim"];
        });
        // this.$parent.$parent.getPlayerInfo();
        this.getLossInfo();
        this.$message({
          message: "领取成功",
          type: "success",
          customClass: "messageIndex",
        });
      } else {
        this.$message.error(data.msg);
      }
      // } else {
      // this.$router.push({
      // 	path: "/",
      // });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  .title {
    width: 100vw;
    height: auto;
    img {
      width: 100%;
      height: auto;
    }
  }
  .nav {
    // height: 40px;
    margin-top: -40px;
    // padding: 5px 0;
    width: 100vw;
    // background-color: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    .isActive {
      //   border-radius: 20px;
      color: #ffc600;
      //   background-color: #30323e;
      border-bottom: 3px solid #ffc600;
      font-size: 18px !important;
    }
    div {
      margin: 0 10px;
      padding: 5px 0;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      //   width: 120px;
      //   height: 120px;
      //   background-color: #252734;
      //   color: white;
      //   cursor: pointer;
      //   font-size: 16px;

      img {
        width: 40px;
        height: auto;
      }
    }
  }
  .right {
    width: 100%;
    height: 500px;
    // background-color: red;
    // padding: 10px;
    margin-top: 30px;
    box-sizing: border-box;
    color: white;
    .day {
          // background-color: red;
          padding: 0 10px;
          .loginQ,.chargeQ {
            .title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .rightT{
                cursor: pointer;
                span{
                  padding: 6px 22px;
                  border-radius: 5px;
                  background-color: #ffc600;
                  color: #403200 !important;
                  font-size: 14px;
                font-weight: 700;
                }
              }
              span {
                // color: black;
              }
            }
            .lists{
              display: flex;
              justify-content: space-between;
              margin: 30px 0;
              .itemActive{
                background-color: rgba(30, 206, 119, .2) !important;
                border: 2px solid rgba(30, 206, 119, .88) !important;
              }
              .keActive{
                background-color: rgba(255, 198, 0, .2) !important;
                border: 2px solid rgba(255, 198, 0, .88) !important;
              }
              .item{ 
                width: 13.5%;
                height: 60px !important;
                height: auto;
                background-color: rgba(141, 42, 255, .5);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px 5px 0 0;
                border: 2px solid rgba(141, 42, 255, .88);
                box-sizing: border-box;
                cursor: pointer;
                .cont1{
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
    .quest {
      width: 100%;
      .topNav {
        width: 100%;
        display: flex;
        justify-content: center;
        div {
          font-size: 14px;
          margin: 0 25px;
          width: 70px;
          height: 50px;
          border-radius: 7px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #252734;
          cursor: pointer;
          img {
            width: 25px;
            height: 25px;
          }
          span {
            margin-left: 5px;
          }
        }
      }
      .boxActive {
        background-color: rgba(251, 198, 0, 0.3) !important;
        color: #fbc600 !important;
      }
      .main {
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        height: 600px;
        margin-top: 10px;
        // overflow: scroll;
        // background-color: red;
        .box1,
        .box2,
        .box3 {
          overflow: scroll;
          height: 100%;
        }
        .boxs {
          width: 100%;
          height: 80px;
          background-color: #252734;
          border-radius: 10px;
          display: flex;
          align-items: center;
          margin-bottom: 4px;
          position: relative;
          font-size: 12px;
          .xian {
            height: 40px;
            width: 4px;
            background-color: #fbc600;
          }
          .bleft {
            margin-left: 10px;
            .c2 {
              display: flex;
              .img {
                display: flex;
                align-items: center;
                img {
                  width: 20px;
                  height: auto;
                }
              }
            }
          }
          .bright {
            padding: 10px 25px;
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translate(0, -50%);
            background-image: url(./img/bg-go.png);
            background-size: 100% 100%;
            color: black;
            cursor: pointer;
          }
        }
      }
    }
  }

  .win-box {
    width: 100vw;
	height: 400px;
	// background-color: red;
    position: fixed;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
    // background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
  }
  .win {
    width: 450px;
    padding: 20px;
    background-color: #1c2230;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;

    .win-span2 {
      padding: 10px 0;
      height: 20px;
      background-color: #e9b10e;
      background-size: 100% 100%;
      border: 1px solid #ffea95;
      color: #000000;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 15px;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }
}

.messageIndex {
  z-index: 9999999999 !important; // 设置的值一定要比2007大，因为dialog的层级是2007
}
</style>